<template>
  <el-tooltip
    :content="label"
    class="item"
    effect="light"
    placement="bottom"
    :enterable="false"
  >
    <el-button
      size="small"
      :class="`table-action-button table-action-button--${buttonColor}`"
      :icon="buttonIcon"
      circle
      :disabled="disabled"
      @click="clickFunction"
    />
  </el-tooltip>
</template>

<script>

export default {
  name: 'TableActionButton',
  props: {
    label: { type: String, default: '' },
    buttonColor: { type: String, default: '' },
    buttonIcon: { type: String, default: '' },
    clickFunction: { type: Function, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.el-button {
  &.table-action-button {
    color: $--color-white;
    border: 0;
    height: 30px;
    width: 30px;
    margin: 0 3px;

    i {
      display: flex;
      justify-content: center;
    }

    @each $color, $value in $table-action-colors {
      &--#{$color}:hover {
        filter: brightness(0.9);
        color: $--color-white;
        background-color: $value;
      }

      &--#{$color}:focus {
        color: $--color-white;
        background-color: $value;
      }
      &--#{$color} {
        background-color: $value;
      }
    }

    &.is-disabled {
      opacity: 0.5;
    }
  }
}
</style>
