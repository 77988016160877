<template>
  <div>
    <span
      v-for="(button, index) in buttons"
      :key="index"
      class="table-action__container"
    >
      <TableActionButton
        v-if="(button.showCondition && button.showCondition(row)) || !button.showCondition"
        :label="$t(`tableButtons.${button.name}`)"
        :button-color="button.color"
        :button-icon="button.icon"
        :click-function="() => $emit('actionClick', button.name)"
        :disabled="button.enableCondition && !button.enableCondition()"
      />
    </span>
  </div>
</template>

<script>

import TableActionButton from '@/components/TableActionButton.vue';
import acl from '../services/acl';

export default {
  name: 'TableActions',
  components: {
    TableActionButton,
  },
  props: {
    configuration: {
      type: Object,
      default: () => {},
    },
    hasPermissions: {
      type: Boolean,
      default: () => true,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      buttons: [],
    };
  },
  created() {
    if (this.hasPermissions) {
      this.buttons = this.configuration.buttons.filter(
        button => acl.userCanUse(button.permissions, true),
      );
    } else this.buttons = this.configuration.buttons;
  },
};
</script>
